export enum CustomUrl {
  RecoverAccount = 'recover-account',
  RecoverPasscode = 'recover-passcode',
  VerifyEmail = 'verify-email',
  RecoverAccess = 'recover-access',
}

export const getAndSetToken = (customUrl: CustomUrl, elementId: string) => {
  let index: string;
  const url = window.location.href;
  url.includes('#') ? (index = '#') : (index = '?');
  const token = url.substring(url.indexOf(index) + 1);
  // Give it me
  // @ts-ignore
  document
    .getElementById(elementId)
    .setAttribute('href', `teal18://${customUrl}${index}${token}`);
};

export const getToken = (): string => {
  const url = window.location.href;
  const token = url.match(/(\?)([^=]+)\=([^&]+)/);
  const tokenValue = token && token[3];
  return tokenValue || '';
};

export const isElementWithinViewport = (
  element: HTMLElement,
  percentVisible: number
): boolean => {
  let rect = element.getBoundingClientRect(),
    windowHeight = window.innerHeight || document.documentElement.clientHeight;

  return !(
    Math.floor(
      100 - ((rect.top >= 0 ? 0 : rect.top) / +-(rect.height / 1)) * 100
    ) < percentVisible ||
    Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) <
      percentVisible
  );
};
