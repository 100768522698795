import React, { useEffect } from 'react';
import styled from 'styled-components';
import { GlobalStyles } from '../../../styles/globalStyles';
import { colors, media } from '../../../styles/theme';
import { getAndSetToken, CustomUrl } from '../../../services/documentHelpers';
import LogoSvg from '../../../assets/svgs/logo.svg';

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
  transform: translate(-50%, -50%);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10vh;
`;

const Logo = styled(LogoSvg)`
  transform: rotate(15deg);
`;

const Paragraph = styled.p`
  text-align: center;
  max-width: 400px;
  font-family: 'Gilroy';
`;

const Title = styled.h2`
  text-align: center;
  font-family: 'GilroySemiBold';
  font-style: normal;
`;

const Button = styled.a`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  bottom: 20px;
  background-color: ${colors.brandYellow};
  border-radius: 10px;
  text-decoration: none;
  margin-top: 30px;
  width: 300px;
  &:hover {
    cursor: pointer;
  }
  ${media.tablet`
    position: relative;
    bottom: 0;
  `}
`;

const VerifyEmail: React.FunctionComponent = () => {
  useEffect(() => {
    getAndSetToken(CustomUrl.VerifyEmail, 'verifyEmail');
  }, []);

  return (
    <Wrapper>
      <GlobalStyles />
      <Content>
        <Logo />
        <Title>Verify my email</Title>
        <Paragraph>
          Tap the button below to open Bó
          <br />
          and verify your email address
        </Paragraph>
        <Button id="verifyEmail">Open Bó</Button>
      </Content>
    </Wrapper>
  );
};

export default VerifyEmail;
